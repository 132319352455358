/*

                    ,`,`,`,`,
. . . .               `\`\`\`\;
`\`\`\`\`,            ~|;!;!;\!
 ~\;\;\;\|\          (--,!!!~`!       .
(--,\\\===~\         (--,|||~`!     ./
 (--,\\\===~\         `,-,~,=,:. _,//
  (--,\\\==~`\        ~-=~-.---|\;/J,
   (--,\\\((```==.    ~'`~/       a |
     (-,.\\('('(`\\.  ~'=~|     \_.  \
        (,--(,(,(,'\\. ~'=|       \\_;>
          (,-( ,(,(,;\\ ~=/        \
          (,-/ (.(.(,;\\,/          )
           (,--/,;,;,;,\\         ./------.
             (==,-;-'`;'         /_,----`. \
     ,.--_,__.-'                    `--.  ` \
    (='~-_,--/        ,       ,!,___--. \  \_)
   (-/~(     |         \   ,_-         | ) /_|
   (~/((\    )\._,      |-'         _,/ /
    \\))))  /   ./~.    |           \_\;
 ,__/////  /   /    )  /
  '===~'   |  |    (, <.
           / /       \. \
         _/ /          \_\
        /_!/            >_\

*/

@import "./normalize.css";
@import "./fonts.css";
@import "./nprogress.css";
@import "./intro.css";
@import "./poll-grid.css";

* {
  box-sizing: border-box;
  /* overscroll-behavior-y: none; */
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #ffffff;
}

img,
svg,
picture,
video {
  display: block;
  height: auto;
}

.ad__slot--mid-content,
.ad__slot--footer {
  padding: 3rem 0;
  overflow: scroll;
}

.rca-poll-2025-container {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: GeneralSans, helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  overflow: hidden;
  padding: 0 0 39%;
  background-color: #2a81a5;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/background-img.jpg);
}

.rca-poll-2025-container .inner-wrapper {
  position: relative;
  z-index: 2;
}

.rca-poll-2025-container .background_circles {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translateX(-50%);
}

svg.background_circles g#arches_top > g#row_1 > *,
svg.background_circles g#arches_top > g#row_2 > * {
  opacity: 0;
}

.rca-poll-2025-container .background_circles_bottom {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translateX(-50%);
}

.rca-poll-2025-container .image-attribution {
  position: absolute;
  z-index: 3;
  bottom: 15px;
  right: 30px;
  font-family: GeneralSans, helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: black;
}

@media only screen and (max-width: 818px) {
  .ad__slot--mid-content {
    padding: 0 0 10px;
    border-top: none;
    border-bottom: none;
  }

  .rca-poll-2025-container {
    padding: 0 0 60px;
    background-position: top center;
    background-image: url(../images/background-img-mobile.jpg);
  }

  .background_circles_bottom {
    display: none;
  }
}
