.intro {
  padding: 54px 30px 41px;
}

.intro img {
  width: 100%;
  height: auto;
}

.intro h1 {
  width: 100%;
  max-width: 558px;
  height: auto;
  margin: 0 auto;
}

.intro .rca-seal {
  padding: 23px 0 12px;
  width: 149px;
  height: auto;
  margin: 0 auto;
}

.intro p {
  font-family: GeneralSans, helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.4em;
  text-align: center;
  color: #ffffff;
  width: 100%;
  max-width: 538px;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (max-width: 818px) {
  .intro {
    padding: 32px 30px 10px;
    display: flex;
    flex-direction: column;
  }

  .intro h1 {
    max-width: 343px;
    order: 1;
  }

  .intro .rca-seal {
    max-width: 101px;
    padding: 0;
    order: 3;
  }

  .intro p {
    font-size: 14px;
    line-height: 23px;
    max-width: 343px;
    padding: 23px 0 2px;
    order: 2;
  }
}
