.poll-grid {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 17px;
  z-index: 2;
}

.poll-grid button {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
  transition: transform 150ms ease, box-shadow 0.15s ease;
}

.poll-grid button > svg > g {
  transition: transform 150ms ease-in-out;
  transform-origin: center;
}

@media (hover: hover) {
  .poll-grid button:hover {
    transform: scale(1.03);
    box-shadow: 0 1px 2px rgb(0 0 0 / 3%), 0 2px 4px rgb(0 0 0 / 3%),
      0 4px 8px rgb(0 0 0 / 3%), 0 8px 16px rgb(0 0 0 / 3%),
      0 16px 32px rgb(0 0 0 / 3%), 0 32px 64px rgb(0 0 0 / 3%);
  }

  .poll-grid button:hover > svg > g {
    transform: rotate(6deg);
  }
}

@media only screen and (max-width: 818px) {
  .poll-grid {
    max-width: 332px;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .poll-grid button {
    width: 156px;
    height: auto;
  }

  .poll-grid button:last-child {
    grid-column: 1 / -1;
    justify-self: center;
  }

  .poll-grid button svg {
    width: 100%;
    height: auto;
  }
}
